<template>
  <!-- <el-button size="mini" type="warning" @click="toExcel"
    ><i class="glyphicon glyphicon-download-alt"></i> 导出
  </el-button> -->
  <el-dropdown trigger="hover" class="BtnSearch">
    <p class="el-dropdown-link">导出</p>
    <template #dropdown>
      <el-dropdown-menu>
        <el-dropdown-item @click="toExcel()">Excel</el-dropdown-item>
        <el-dropdown-item @click="toPDF()">PDF</el-dropdown-item>
      </el-dropdown-menu>
    </template>
  </el-dropdown>
</template>

<script>
import axios from "axios";
axios.defaults.headers.post["Content-Type"] =
  "application/x-www-fromurlencodeed";
axios.defaults.withCredentials = true;
import { ElLoading, ElMessage } from "element-plus";
import { baseUrl } from "@/request/api";
export default {
  name: "Data2Excel",
  props: {
    api: String, //  接受一个字符串:请求地址
    data: Object, // 接收一个对象:请求携带参数
    fileName: String, //文件名称
  },
  setup(props) {
    const toExcel = () => {
      const loading = ElLoading.service({
        lock: true,
        text: "正在导出",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const dataa = {
        ...(props.data),
        export_excel:1
      };
      axios
        .post(`${baseUrl}${props.api}`, dataa, {
          responseType: "blob",
        })
        .then((res) => {
          console.log(res);
          if (res.type == "application/json") {
            ElMessage({
              message: "导出失败:暂无可导出的内容",
              type: "error",
            });
            loading.close();
          } else {
            let fileName = props.fileName + ".xlsx"
            let url = window.URL.createObjectURL(new Blob([res]));
            let link = document.createElement("a");
            link.style.display = "none";
            link.href = url;
            link.setAttribute("download", fileName); //指定下载后的文件名，防跳转
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link); // 移除对象
            loading.close();
          }
        })
        .catch(() => {
          loading.close();
          ElMessage({
            message: "导出失败",
            type: "error",
          });
        });
    };
    const toPDF = () => {
      const loading = ElLoading.service({
        lock: true,
        text: "正在导出",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const dataa = {
        ...(props.data),
        export_pdf:1
      };
      axios
        .post(`${baseUrl}${props.api}`, dataa, {
          responseType: "blob",
        })
        .then((res) => {
          console.log(res);
          if (res.type == "application/json") {
            ElMessage({
              message: "导出失败:暂无可导出的内容",
              type: "error",
            });
            loading.close();
          } else {
            let fileName = props.fileName + ".pdf"
            let url = window.URL.createObjectURL(new Blob([res]));
            let link = document.createElement("a");
            link.style.display = "none";
            link.href = url;
            link.setAttribute("download", fileName); //指定下载后的文件名，防跳转
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link); // 移除对象
            loading.close();
          }
        })
        .catch(() => {
          loading.close();
          ElMessage({
            message: "导出失败",
            type: "error",
          });
        });
    };
    return {
      toExcel,
      toPDF
    };
  },
};
</script>

<style scoped></style>
